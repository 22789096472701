import {Component, OnInit, ViewEncapsulation} from '@angular/core';
// it's important for working in Safari Browser
interface SVGElement extends Element {
  beginElement(): SVGElement;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
