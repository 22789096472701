import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// it's important for working in Safari Browser
interface SVGElement extends Element {
  beginElement(): SVGElement;
}

@Component({
  selector: 'app-second',
  templateUrl: './second.component.html',
  styleUrls: ['./second.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SecondComponent implements OnInit {
  loaded = false;
  html0 = '';

  flow1; flow2; flowV2x; flowAData;

  // for short and long path for setting the right dasharray
  shortPathWidth = 300;
  longPathWidth  = 1000;

  // some default animation properties
  defaultOpacityFrom = 0;
  defaultOpacityTo = 1;
  defaultGlowingMinOpacity = 0.3;
  defaultGlowingDuration = '3s';
  defaultAnimationTime = '1s';
  defaultAnimationFill = 'freeze';
  defaultStrokeDashOffsetEnd = 0;
  defaultStrokeDashOffsetStart = 20;

  ngOnInit() {
    this.loaded = true;
    this.flow1 = {
      turnedOn: false,
      // turn off visibility of all elements that should be turn on later;
      visibility: [

        /* BUS ELEMENTS VISIBILITY */
         '[id^=bus_legend]',
         '[id^=bus_equipmet]',
         '[id^=bus_pathline]',

         '[id=pathline_obu_v2x_rsu_v2x]',
         '[id=pathline_can_standartBO]',
         '[id=pathline_controller_egnine_can]',
         '[id=pathline_adas_can]',
         '[id=pathline_sau_can]',
         '[id=pathline_technical_vision_controller_sau]',
         '[id=pathline_standartBO_4G]',
        /* BUS ELEMENTS VISIBILITY */


        '[id^=special_transport_legend]',
        '[id^=special_transport_equipmet]',
        '[id^=special_transport_pathline]',

        '[id=pathline_v2x_rsu_v2x]',
        '[id=pathline_notstandartBO_gnss_acselerometr_4G]',
      ],

      // turn off visibility of paths by setting dash-offset and dash-array
      pathline: [],

      // turn off visibility of Telematics block
      glowing: [
        // '[id^="Rectangle 8.2"]'
      ],

      // TODO - stop executing functions on ngOnInit()
      // Describes the flow of animations
      animationQueue: [
        // Start of The Flow - Always One Animation
        [ this.opacityAnimation({id: 'special_transport_legend_notstandartBO_gnss_acselerometr', dur: '1s'})],
        [
          this.opacityAnimation({id: 'special_transport_equipmet_road_condition_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_equipmet_attachment_position_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_equipmet_road_fuel_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_equipmet_filling_sensor', dur: '1s'}),
        ],
        [
          this.opacityAnimation({id: 'special_transport_legend_attachment_position_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_legend_road_condition_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_legend_fuel_level_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_legend_filling_sensor', dur: '1s'}),
        ],
        [
          this.opacityAnimation({id: 'special_transport_pathline_attachment_position_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_pathline_road_condition_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_pathline_road_fuel_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_pathline_filling_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),

          this.pathAnimation({id: 'special_transport_pathline_attachment_position_sensor_notstandartBO_gnss_acselerometr', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'special_transport_pathline_road_condition_sensor_notstandartBO_gnss_acselerometr', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'special_transport_pathline_road_fuel_sensor_notstandartBO_gnss_acselerometr', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'special_transport_pathline_filling_sensor_notstandartBO_gnss_acselerometr', repeatCount: 'indefinite'}),
        ],
        [
          this.opacityAnimation({id: 'special_transport_equipmet_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_equipmete_v2x_obu', dur: '1s'}),
        ],
        [
          this.opacityAnimation({id: 'special_transport_legend_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_legend_gv2x_obu', dur: '1s'}),
        ],
        [
          this.opacityAnimation({id: 'special_transport_pathline_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'special_transport_pathline_v2x_obu_rsu_v2x', dur: '1s'}),


          this.pathAnimation({id: 'special_transport_pathline_glonass', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'special_transport_pathline_v2x_obu_rsu_v2x', repeatCount: 'indefinite'}),

        ],
        [
          this.opacityAnimation({id: 'pathline_notstandartBO_gnss_acselerometr_4G', dur: '1s'}, 'truckDataSattelit'),
          this.pathAnimation({id: 'pathline_notstandartBO_gnss_acselerometr_4G', repeatCount: 'indefinite', from: 24, to: 0}),
        ]
      ]
    };

    this.flow2 = {
      turnedOn: false,
      visibility: [
        /* SPECIAL TRANSPORT ELEMENTS VISIBILITY */
         '[id^=special_transport_legend]',
         '[id^=special_transport_equipmet]',
         '[id^=special_transport_pathline]',

         '[id=pathline_v2x_rsu_v2x]',
         '[id=pathline_notstandartBO_gnss_acselerometr_4G]',
        /* SPECIAL TRANSPORT ELEMENTS VISIBILITY */

        '[id^=bus_legend]',
        '[id^=bus_equipmet]',
        '[id^=bus_pathline]',

        '[id=pathline_obu_v2x_rsu_v2x]',
        '[id=pathline_can_standartBO]',
        '[id=pathline_controller_egnine_can]',
        '[id=pathline_adas_can]',
        '[id=pathline_sau_can]',
        '[id=pathline_technical_vision_controller_sau]',
        '[id=pathline_standartBO_4G]'
      ],
      pathline: [],
      glowing: [],
      animationQueue: [
        [this.opacityAnimation({id: 'bus_legend_standartbo_gnss_acselerometr', dur: '1s'})],
        [
          this.opacityAnimation({id: 'bus_equipmet_passenger_counter1', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_motion_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_external_camera', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_camera_inside', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_smoke_detector', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_fuel_level_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmet_video_controller', dur: '1s'}),

        ],
        [
          this.opacityAnimation({id: 'bus_legend_passenger_counter1', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_motion_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_camera_inside', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_external_camera', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_smoke_detector', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_fuel_level_sensor', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_video_controller', dur: '1s'}),

        ],
        [
          this.opacityAnimation({id: 'bus_pathline_passenger_counter1_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_motion_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_camera_inside_video_controller', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_fuel_level_sensor_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_smoke_detector_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_video_controller_notstandartBO_gnss_acselerometr', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_camera_outside_video_controller', dur: '1s'}),


          this.pathAnimation({id: 'bus_pathline_passenger_counter1_notstandartBO_gnss_acselerometr', from: 0, to: 20, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_motion_sensor_notstandartBO_gnss_acselerometr', from: 0, to: 20, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_camera_inside_video_controller', from: 0, to: 20, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_fuel_level_sensor_notstandartBO_gnss_acselerometr', from: 20, to: 0, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_smoke_detector_notstandartBO_gnss_acselerometr', from: 0, to: 20, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_video_controller_notstandartBO_gnss_acselerometr', from: 0, to: 20, repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_camera_outside_video_controller', from: 20, to: 0, repeatCount: 'indefinite'}),
        ],
        [
          this.opacityAnimation({id: 'bus_equipmet_tachograph', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmete_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'bus_equipmete_v2x_obu', dur: '1s'}),

        ],
        [
          this.opacityAnimation({id: 'bus_legend_tachograph', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'bus_legend_gv2x_obu', dur: '1s'}),


        ],
        [
          this.opacityAnimation({id: 'bus_pathline_tahograph', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_glonass', dur: '1s'}),
          this.opacityAnimation({id: 'bus_pathline_v2x_obu_rsu_v2x', dur: '1s'}),


          this.pathAnimation({id: 'bus_pathline_tahograph', dur: '1s', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_glonass', dur: '1s', repeatCount: 'indefinite'}),
          this.pathAnimation({id: 'bus_pathline_v2x_obu_rsu_v2x', from: 0, to: 20, dur: '1s', repeatCount: 'indefinite'}),

        ],
        [
          this.opacityAnimation({id: 'pathline_standartBO_4G', dur: '1s'}, 'carDataSattelit'),
          this.pathAnimation({id: 'pathline_standartBO_4G', repeatCount: 'indefinite', from: 24, to: 0}),
        ]
      ]
    };
    this.flowV2x = {
      turnedOn: false,
      // turn off visibility of all elements that should be turn on later;
      visibility: [
        '[id=pathline_rsu_v2xplatform]'
      ],
      pathline: [],
      glowing: [],
      animationQueue: [
        [
          this.opacityAnimation({id: 'legend_rsu_v2x', dur: '1s'})
        ],
        [
          this.opacityAnimation({id: 'pathline_rsu_v2xplatform', dur: '1s', begin: 'legend_rsu_v2x_Show.end + 7s'}),
          this.pathAnimation({id: 'pathline_rsu_v2xplatform', repeatCount: 'indefinite', from: 0, to: 24})
        ]
      ]
    };

    this.flowAData = {
      turnedOn: false,
      // turn off visibility of all elements that should be turn on later;
      visibility: [
        '[id=pathline4G_telematic_platform]',
        '[id=pathline_v2xplatform_autodata_4]',
        '[id=pathline_v2xplatform_autodata_2]',
        '[id=pathline_v2xplatform_autodata_3]',

        '[id="Rectangle 7.4_2"]',
        '[id="Rectangle 7.5_2"]',
        '[id="Rectangle 7.6_2"]',
        '[id="Rectangle 7.7_2"]',
        '[id="Rectangle 7.8_2"]',
        '[id="Rectangle 7.9_2"]',

        '[id="Rectangle 7.4_3"]',
        '[id="Rectangle 7.5_3"]',
        '[id="Rectangle 7.6_3"]',
        '[id="Rectangle 7.7_3"]',
        '[id="Rectangle 7.8_3"]',
        '[id="Rectangle 7.9_3"]',

        '[id="Rectangle 7.4_4"]',
        '[id="Rectangle 7.5_4"]',
        '[id="Rectangle 7.6_4"]',
        '[id="Rectangle 7.7_4"]',
        '[id="Rectangle 7.8_4"]',
        '[id="Rectangle 7.9_4"]',

        '[id="Rectangle 7.4"]',
        '[id="Rectangle 7.5"]',
        '[id="Rectangle 7.6"]',
        '[id="Rectangle 7.7"]',
        '[id="Rectangle 7.8"]',
        '[id="Rectangle 7.9"]',

        '#graph3 g[id^=Ellipse]',

        '[id=Ellipse_19]',
        '[id=Ellipse_20]',
        '[id=Ellipse_21]',
        '[id="Ellipse 2"]',
        '[id="Ellipse 2.1"]',
        '[id="Ellipse 2.2"]',
      ],
      pathline: [],
      glowing: [],
      animationQueue: [
        [
          this.opacityAnimation({id: 'legend_4G', dur: '1s'})
        ],
        [
          this.opacityAnimation({id: 'pathline4G_telematic_platform', dur: '1s', begin: 'legend_4G_Show.end + 8s'}),
          this.pathAnimation({id: 'pathline4G_telematic_platform', repeatCount: 'indefinite', from: 24, to: 0}),
          this.opacityAnimation({id: 'bus_pathline_telematic_platform', dur: '1s', begin: 'legend_4G_Show.end + 5s'}),
          this.pathAnimation({id: 'bus_pathline_telematic_platform', repeatCount: 'indefinite'}),
        ],
        [
          this.opacityAnimation({id: 'pathline_v2xplatform_autodata_4', dur: '1s', begin: 'legend_4G_Show.end + 8s'}),
          this.pathAnimation({id: 'pathline_v2xplatform_autodata_4', repeatCount: 'indefinite', from: 0, to: 24}),
          this.opacityAnimation({id: 'pathline_v2xplatform_autodata_3', dur: '1s', begin: 'legend_4G_Show.end + 8s'}),
          this.pathAnimation({id: 'pathline_v2xplatform_autodata_3', repeatCount: 'indefinite', from: 0, to: 24}),
          this.opacityAnimation({id: 'pathline_v2xplatform_autodata_2', dur: '1s', begin: 'legend_4G_Show.end + 8s'}),
          this.pathAnimation({id: 'pathline_v2xplatform_autodata_2', repeatCount: 'indefinite', from: 0, to: 24}),
        ],
        [
          this.opacityAnimation({id: 'pathline_v2xplatform_autodata', dur: '1s', begin: 'legend_4G_Show.end + 8s'}),
          this.pathAnimation({id: 'pathline_v2xplatform_autodata', repeatCount: 'indefinite', from: 0, to: 24}),
        ],
        this.animateAutoDataPlatform('legend_4G_Show.end + 10s'),
        this.animateDiagrams('coll_1_flowExchangeLeft0.end + 1s'),
        this.animateV2XPlatform('legend_4G_Show.end + 10s'),
        this.animateTelematicsPlatform('legend_4G_Show.end + 10s'),
        this.animateMovingQueue('coll_1_', 'legend_4G_Show.end + 10.5s',
          ['#Rectangle 7.4_2', '#Rectangle 7.5_2', '#Rectangle 7.6_2', '#Rectangle 7.7_2', '#Rectangle 7.8_2', '#Rectangle 7.9_2']
        ),
        this.animateMovingQueue('coll_2_', 'legend_4G_Show.end + 10s',
          ['#Rectangle 7.4_3', '#Rectangle 7.5_3', '#Rectangle 7.6_3', '#Rectangle 7.7_3', '#Rectangle 7.8_3', '#Rectangle 7.9_3']
        ),
        this.animateMovingQueue('coll_3_', 'legend_4G_Show.end + 9s',
          ['#Rectangle 7.4_4', '#Rectangle 7.5_4', '#Rectangle 7.6_4', '#Rectangle 7.7_4', '#Rectangle 7.8_4', '#Rectangle 7.9_4']
        ),
        this.animateMovingQueue('coll_4_', 'legend_4G_Show.end + 11s',
          ['#Rectangle 7.4', '#Rectangle 7.5', '#Rectangle 7.6', '#Rectangle 7.7', '#Rectangle 7.8', '#Rectangle 7.9']
        ),
      ],
    };

    const truckEquipmentNotStandartBO = document.getElementById('notstandartBO_gnss_acselerometr_2');
    truckEquipmentNotStandartBO.addEventListener('click', this.clickTruckNotStandartBO.bind(this));

    const carEquipmentStandartBO = document.getElementById('notstandartBO_gnss_acselerometr');
    carEquipmentStandartBO.addEventListener('click', this.clickCarStandartBO.bind(this));

    this.html0 += this.animateItem(this.glowingAnimation({'xlink:href': '#notstandartBO_gnss_acselerometr_2', values: '0.4; 1; 0.4', dur: '1s', begin: 'indefinite', repeatCount: 'indefinite'}));
    this.html0 += this.animateItem(this.glowingAnimation({'xlink:href': '#notstandartBO_gnss_acselerometr', values: '0.4; 1; 0.4', dur: '1s', begin: 'indefinite', repeatCount: 'indefinite'}));
    document.querySelector('#animation').innerHTML = this.html0;
    const element00: SVGElement = document.querySelectorAll('svg')[1].getElementsByTagName('animate')[0] as SVGElement;
    const element01: SVGElement = document.querySelectorAll('svg')[1].getElementsByTagName('animate')[1] as SVGElement;
    element00.beginElement();
    element01.beginElement();
  }

  clickCarStandartBO(event) {
    this.toggleFlow(2);
    this.toggleFlow('V2x');
    this.toggleFlow('AData');
  }

  clickTruckNotStandartBO(event) {
    this.toggleFlow(1);
    this.toggleFlow('V2x');
    this.toggleFlow('AData');
  }

  // turn on the flow
  toggleFlow(id) {

    if (typeof id === 'string') {
      if ((this.flow1.turnedOn || this.flow2.turnedOn) && this['flow' + id].turnedOn) {
        return;
      }
      if ((this.flow1.turnedOn || this.flow2.turnedOn) && !this['flow' + id].turnedOn) {
        this['flow' + id].turnedOn = true;
      }

      if (!this.flow1.turnedOn && !this.flow2.turnedOn && this['flow' + id].turnedOn) {
        this['flow' + id].turnedOn = false;
      }
    } else {
      this['flow' + id].turnedOn = !this['flow' + id].turnedOn;
    }

    this['flow' + id].visibility.forEach(flowItem => {
      this.toggleVisibility(flowItem, this['flow' + id].turnedOn);
    });

    this['flow' + id].pathline.forEach(pathLineItem => {
      this.togglePathlineVisibility(pathLineItem.selector, pathLineItem.width, this['flow' + id].turnedOn);
    });

    this['flow' + id].glowing.forEach(glowingItem => {
      this.toggleGlowingVisibility(glowingItem, this['flow' + id].turnedOn);
    });

    let html = '';

    if (this['flow' + id].turnedOn) {
      this['flow' + id].animationQueue.forEach((chainedAnimationItem, index) => {
        if (index === 0) {
          chainedAnimationItem.forEach(item => {
            item.begin = item.begin || 'indefinite';
            html += this.animateItem(item);
          });
        } else {
          chainedAnimationItem.forEach(item => {
            item.begin = item.begin || this['flow' + id].animationQueue[index - 1][0].id + '.end';
            html += this.animateItem(item);
          });
        }
      });
    } else {
      html = '';
    }

    document.querySelector('#animation_description' + id).innerHTML = html;

    // start on animation
    switch (id) {
      case 1:
        const element: SVGElement = document.querySelectorAll('svg')[2].getElementsByTagName('animate')[0] as SVGElement;
        if (this['flow' + id].turnedOn) {
          element.beginElement();
        }
        break;
      case 2:
        const element2: SVGElement = document.querySelectorAll('svg')[3].getElementsByTagName('animate')[0] as SVGElement;
        if (this['flow' + id].turnedOn) {
          element2.beginElement();
        }
        break;
      case 'V2x':
        const element3: SVGElement = document.querySelectorAll('svg')[4].getElementsByTagName('animate')[0] as SVGElement;
        if (this['flow' + id].turnedOn) {
          element3.beginElement();
        }
        break;
      case 'AData':
        const element4: SVGElement = document.querySelectorAll('svg')[5].getElementsByTagName('animate')[0] as SVGElement;
        if (this['flow' + id].turnedOn) {
          element4.beginElement();
        }
        break;
    }
  }

  // turn off the visibility of selector by changing the opacity to 0
  toggleVisibility(selector, vis) {
    const items = document.querySelectorAll(selector);
    items.forEach(item => {
      if (vis) {
        item.style.opacity = '0';
      } else {
        item.style.opacity = '1';
      }
    });
  }

  // turn off the visibility of Telematics block
  toggleGlowingVisibility(selector, vis) {
    const items = document.querySelectorAll(selector);
    items.forEach(item => {
      let id = item.getAttribute('id');
      id = id.split('_')[1];
      if ((id % 2) === 1 ) {
        if (vis) {
          item.style.opacity = '0';
        } else {
          item.style.opacity = '1';
        }

      }
    });
  }

  // because pathline doesn't use opacity - used stroke-dashoffset
  togglePathlineVisibility(selector, width, vis) {
    const items = document.querySelectorAll(selector);
    items.forEach(item => {
      // item.style['stroke-width'] = '2px';
      // item.style['stroke-dashoffset'] = width;
      // item.style['stroke-dasharray'] = width;
    });
  }

  // animation for opacity
  opacityAnimation(item, key = '') {
    const animateObject: any = {};
    animateObject['xlink:href'] = (item.id.indexOf('#') > -1) ? item.id : '#' + item.id;
    if (key !== '') {
      animateObject.id = key;
    } else {
      animateObject.id = item.id + '_Show' || '';
    }

    animateObject.attributeName = 'opacity';
    animateObject.from = item.from || this.defaultOpacityFrom;
    animateObject.to = item.to || this.defaultOpacityTo;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for glowing
  glowingAnimation(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'] || item.id + '_Glow' || '';
    animateObject.id = item.id + '_Glow' || '';
    animateObject.attributeName = 'opacity';
    animateObject.values = item.values || `${this.defaultGlowingMinOpacity};1;${this.defaultGlowingMinOpacity}`;
    animateObject.dur = item.dur || this.defaultGlowingDuration;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for drowing path
  pathAnimation(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'] || '#' + item.id;
    animateObject.id = item.id + '_Path' || '';
    animateObject.attributeName = 'stroke-dashoffset';
    animateObject.from = (item.from === undefined) ? this.defaultStrokeDashOffsetStart : item.from; // this cos of 0 value
    animateObject.to = (item.to === undefined) ? this.defaultStrokeDashOffsetEnd : item.to; // this cos of 0 value
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for moving blocks
  animateMovingX(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = 'x';
    animateObject.from = item.from;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    animateObject.begin = item.begin;
    return animateObject;
  }

  // animation for drawing lines at charts or width of element
  animateWidth(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = 'width';
    animateObject.from = item.from || 0;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for drawing lines at charts or changing the y-position of element
  animateMovingY(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = 'y';
    animateObject.from = item.from;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for drawing lines at charts or changing the height of element
  animateHeight(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = 'height';
    animateObject.from = item.from || 0;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // block of animations for animating diagram
  animateDiagrams(startAnimation) {
    const arrayOfAnimation = [];
    const durationForCharts = '3s';
    // Pie chart
    // Ellipses
    const ellipsesOnDiagram = ['Ellipse_12', 'Ellipse_13', 'Ellipse_14', 'Ellipse 2', 'Ellipse 2.1', 'Ellipse 2.2'];
    // Lines
    const linesOnDiagram = ['Rectangle 9', 'Rectangle 9.1', 'Rectangle 9.2', 'Rectangle 9.3', 'Rectangle 9.4', 'Rectangle 9.5'];
    // Map
    const ellipsesOnMap = document.querySelectorAll('#graph3 g[id^=Ellipse]');
    // Chart Lines
    const lines = ['Vector 44_2', 'Vector 45_2', 'Vector 46_2'];
    // Chart diagram (horizontal)
    const rectsHorizontal = ['Rectangle 10_2', 'Rectangle 10.1', 'Rectangle 10.2', 'Rectangle 10.3', 'Rectangle 10.4', 'Rectangle 10.5', 'Rectangle 10.6', 'Rectangle 10.7'];
    // Chart diagram (vertical)
    const rectsVertical = ['Rectangle 11', 'Rectangle 11.1', 'Rectangle 11.3', 'Rectangle 11.4', 'Rectangle 11.5', 'Rectangle 11.6', 'Rectangle 11.2', 'Rectangle 11.7', 'Rectangle 11.8', 'Rectangle 11.9', 'Rectangle 11.10'];

    // Start Showing ellipses on pie diagram
    ellipsesOnDiagram.forEach((item, i) => {
      arrayOfAnimation.push(this.opacityAnimation( {id: item, dur: durationForCharts, begin: `${startAnimation}`}));
    });

    // Start showing lines on pie diagram
    linesOnDiagram.forEach((item, i) => {
      const width = document.getElementById(item).getAttribute('width');
      document.getElementById(item).setAttribute('width', '0');
      arrayOfAnimation.push(this.animateWidth({'xlink:href': `#${item}`, to: width, begin: startAnimation}));
    });

    // Start showing ellipses on map
    ellipsesOnMap.forEach((item, i) => {
      arrayOfAnimation.push(
        this.opacityAnimation({id: item.getAttribute('id'), dur: '1s', begin:  `${(i > 0) ? ellipsesOnMap[i - 1].getAttribute('id') + '_Show.end' : startAnimation }`})
      );
    });

    // Start showing lines on linearChart
    lines.forEach((item, i) => {
      document.getElementById(lines[i]).style['stroke-dashoffset'] = 550;
      document.getElementById(lines[i]).style['stroke-dasharray'] = 550;
      arrayOfAnimation.push(this.pathAnimation({'xlink:href': `#${item}`, dur: '5s', begin: startAnimation, from: '550'}));
    });

    // Start showing lines on barChart - horizontal
    rectsHorizontal.forEach((item, i) => {
      const width = document.getElementById(item).getAttribute('width');
      document.getElementById(item).setAttribute('width', '0');
      arrayOfAnimation.push(this.animateWidth({'xlink:href': `#${item}`, to: width, begin: startAnimation, dur: '3s'}));
    });

    // Start showing lines on barChart - vertical
    rectsVertical.forEach((item, i) => {
      const height = +(document.getElementById(item).getAttribute('height'));
      document.getElementById(item).setAttribute('height', '0');
      const startCoordinate = Math.abs(169 - height);
      arrayOfAnimation.push(this.animateHeight({'xlink:href': `#${item}`, to: height, dur: '3s', begin: startAnimation}));
      arrayOfAnimation.push(this.animateMovingY({'xlink:href': `#${item}`, to: startCoordinate, from: '169', dur: '3s', begin: startAnimation}));
    });

    return arrayOfAnimation;
  }

  // block of animation for moving elements
  animateMovingQueue(suffix, startAnimation, elements) {
    const component = this;
    // Elements in the queue, six because one of them is flying
    // const elements = ['#Rectangle 7.4_2', '#Rectangle 7.5_2', '#Rectangle 7.6_2', '#Rectangle 7.7_2', '#Rectangle 7.8_2', '#Rectangle 7.9_2'];
    const durationForOpacity = '0.01s';
    const durationForFlying = '0.01s';

    function cycling(object1) {
      const arrayOfAnimation = [];
      for (let i = 0; i < 6; i++) {
        if (i === 0) {
          arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[i]}`, begin: `${suffix}flowExchangeCompleted5.end; ${suffix}endCycleAnimation${i + 5}.end`, from: '285', to: '255', id: `${suffix}flowExchangeLeft${i}`, dur: durationForFlying}));
        } else {
          arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[i]}`, begin: `${suffix}endCycleAnimation${i - 1}.end`, from: '285', to: '255', id: `${suffix}flowExchangeLeft${i}`, dur: durationForFlying}));
        }
        arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[((i + 1) > 5) ? (i + 1 - 6) : (i + 1)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '305', to: '285'}));
        arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[((i + 2) > 5) ? (i + 2 - 6) : (i + 2)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '325', to: '305'}));
        arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[((i + 3) > 5) ? (i + 3 - 6) : (i + 3)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '345', to: '325'}));
        arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[((i + 4) > 5) ? (i + 4 - 6) : (i + 4)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '365', to: '345'}));
        arrayOfAnimation.push(object1.opacityAnimation({id: `${elements[((i + 5) > 5) ? (i + 5 - 6) : (i + 5)]}`, dur: durationForOpacity, begin: `${suffix}flowExchangeLeft${i}.end`}));
        arrayOfAnimation.push(object1.animateMovingY({'xlink:href': `${elements[((i + 5) > 5) ? (i + 5 - 6) : (i + 5)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '385', to: '365', id: `${suffix}endCycleAnimation${i}`}));
        arrayOfAnimation.push(object1.opacityAnimation({id: `${elements[i]}`, dur: durationForOpacity, begin: `${suffix}flowExchangeLeft${i}.end`, from: '1', to: '0'}));
      }
      // TODO: trail of the element (?) - no need to put it in cycle
      return arrayOfAnimation;
    }

    const sequence1 = [
      // preparing queue of information
      this.opacityAnimation({id: elements[0], dur: durationForOpacity, begin: startAnimation}),
      this.animateMovingY({'xlink:href': elements[0], begin: startAnimation, from: '405', to: '285', id: `${suffix}flowExchangeCompleted1`}),
      this.opacityAnimation({id: elements[1], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted1.end`}),
      this.animateMovingY({'xlink:href': elements[1], begin: `${suffix}flowExchangeCompleted1.end`, from: '405', to: '305', id: `${suffix}flowExchangeCompleted2`}),
      this.opacityAnimation({id: elements[2], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted2.end`}),
      this.animateMovingY({'xlink:href': elements[2], begin: `${suffix}flowExchangeCompleted2.end`, from: '405', to: '325', id: `${suffix}flowExchangeCompleted3`}),
      this.opacityAnimation({id: elements[3], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted3.end`}),
      this.animateMovingY({'xlink:href': elements[3], begin: `${suffix}flowExchangeCompleted3.end`, from: '405', to: '345', id: `${suffix}flowExchangeCompleted4`}),
      this.opacityAnimation({id: elements[4], dur: durationForOpacity, begin: 'flowExchangeCompleted4.end'}),
      this.animateMovingY({'xlink:href': elements[4], begin: `${suffix}flowExchangeCompleted4.end`, from: '405', to: '365', id: `${suffix}flowExchangeCompleted5`}),
    ];

    return [ ...sequence1, ...cycling(component)];
  }

  // animate glowing AutoDataPlatform
  animateAutoDataPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 1; i <= 9; i++) {
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#hex_odd_${i}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `autoDataPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // animate glowing V2XPlatform
  animateV2XPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 1; i <= 3; i++) {
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#hex_odd_2.${i}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `V2XPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // animate glowing Telematics
  animateTelematicsPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 0; i < 60; i++) {
      if (i && (i % 2 === 0)) {
        continue;
      }
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#Rectangle 8.2${i ? '_' + i : ''}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `TelematicsPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // animate path from V2X platform to AutoData platform
  animatePathToAutoData(startAnimation) {
    const animationArray: any = [];
    animationArray.push(
      this.opacityAnimation({id: 'pathline_v2xplatform_autodata', dur: '0.1s', begin: startAnimation})
    );
    animationArray.push(
      this.pathAnimation({
        id: 'pathline_v2xplatform_autodata',
        dur: '3s',
        begin: startAnimation,
        from: '0',
        to: '50',
        repeatCount: 'indefinite'})
    );
    return animationArray;
  }

  // make animation tag
  animateItem(item) {
    let html = '<animate ';
    Object.keys(item).forEach((key) => {
      html += `${key}="${item[key]}" `;
    });
    html += ' />';
    return html;
  }

}
