import {Component, OnInit, ViewEncapsulation} from '@angular/core';
// it's important for working in Safari Browser
interface SVGElement extends Element {
  beginElement(): SVGElement;
  endElement(): SVGElement;
}

@Component({
  selector: 'app-first',
  templateUrl: './first.component.html',
  styleUrls: ['./first.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FirstComponent implements OnInit {

  // some default animation properties
  defaultOpacityFrom = 0;
  defaultOpacityTo = 1;
  defaultGlowingMinOpacity = 0.3;
  defaultGlowingDuration = '3s';
  defaultAnimationTime = '1s';
  defaultAnimationFill = 'freeze';
  defaultStrokeDashOffsetEnd = 0;
  defaultStrokeDashOffsetStart = 10;

  page = {

    satellit4g: {
    id: 'satellit4g',
    hide: true,
    animated: false,
    path: {
      id: 'pathline4G_telematic_platform',
      animation: {from: 24, to: 0}
    },
    legend: {
      id: 'legend_4G'
    },
  },

    standartBO: {
    id: 'standartBO',
    hide: false,
    animated: false,
    path: {
      id: 'pathline_standartBO_4G',
      animation: {from: 24, to: 0}
    },
    legend: {
      id: 'car_legend_standartbo_gnss_acselerometr'
    },
  },

    sensor_lidar: {
    id: 'sensor_lidar',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_lidar_technical_vision_controller'
    }
  },

    sensor_camera: {
    id: 'sensor_camera',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_camera_technical_vision_controller'
    }
  },

    sensor_radar: {
    id: 'sensor_radar',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_radar_technical_vision_controller'
    }
  },

    notstandartBO_gnss_acselerometr: {
    id: 'notstandartBO_gnss_acselerometr',
    hide: false,
    animated: false,
    path: {
      id: 'pathline_notstandartBO_gnss_acselerometr_4G',
      animation: {from: 24, to: 0}
    },
    legend: {
      id: 'truck_legend_notstandartBO_gnss_acselerometr'
    }
  },

    car_equipmet_can: {
    id: 'car_equipmet_can',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_can_standartBO',
      animation: {from: 12, to: 0}
    },
    legend: {
      id: 'car_legend_can'
    }
  },

    car_equipmet_adas: {
    id: 'car_equipmet_adas',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_adas_can'
    },
    legend: {
      id: 'car_legend_adas'
    }
  },

    sensor_ebs_esp: {
    id: 'sensor_ebs_esp',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_ebs_esp_adas'
    }
  },

    sensor_lane_retention_sensor: {
    id: 'sensor_lane_retention_sensor',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_lane_adas'
    }
  },

    car_equipmet_controller_egnine: {
    id: 'car_equipmet_controller_egnine',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_controller_egnine_can'
    },
    legend: {
      id: 'car_legend_controller_egnine'
    }
  },

    sensor_speed: {
    id: 'sensor_speed',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_speed_controller_egnine'
    }
  },

    sensor_controller_egnine: {
    id: 'sensor_controller_egnine',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_sensor_controller_egnine_controller_egnine'
    }
  },

    car_equipmet_sau: {
      id: 'car_equipmet_sau',
      hide: true,
      animated: false,
      path: {
        id: 'pathline_sau_can'
      },
      legend: {
        id: 'car_legend_sau'
      }
    },

    car_equipmet_technical_vision_controller: {
      id: 'car_equipmet_technical_vision_controller',
      hide: true,
      animated: false,
      path: {
        id: 'pathline_technical_vision_controller_sau'
      },
      legend: {
        id: 'car_legend_technical_vision_controller'
      }
    },

    car_equipmet_obu_v2x: {
    id: 'car_equipmet_obu_v2x',
    hide: true,
    animated: false,
    path: {
      id: 'pathline_obu_v2x_rsu_v2x'
    },
    legend: {
      id: 'car_legend_obu_v2x'
    }
  },

    car_equipmet_era_glonass: {
    id: 'car_equipmet_era_glonass',
    hide: true,
    animated: false,
    legend: {
      id: 'car_legend_era_glonass'
    }
  },

    rsu_v2x: {
      id: 'rsu_v2x',
      hide: true,
      animated: false,
      path: {
        id: 'pathline_rsu_v2xplatform',
        animation: {from: 24, to: 0}
      },
      legend: {
        id: 'legend_rsu_v2x'
      }
    },

    truck_equipmet_tachograph: {
      id: 'truck_equipmet_tachograph',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_tachograph'
      }
    },

    truck_equipmet_platon: {
      id: 'truck_equipmet_platon',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_platon'
      }
    },

    truck_equipmete_glonass: {
      id: 'truck_equipmete_glonass',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_era_glonass'
      }
    },

    truck_equipmet_fuel_level_sensor: {
      id: 'truck_equipmet_fuel_level_sensor',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_fuel_level_sensor'
      },
      path: {
        id: 'pathline_fuel_level_sensor'
      }
    },

    truck_equipmet_temperature_sensor: {
      id: 'truck_equipmet_temperature_sensor',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_temperature_sensor'
      },
      path: {
        id: 'pathline_temperature_sensor'
      }
    },

    truck_equipmet_doorsensor: {
      id: 'truck_equipmet_doorsensor',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_doorsensor'
      },
      path: {
        id: 'pathline_doorsensor'
      }
    },

    truck_equipmet_v2x: {
      id: 'truck_equipmet_v2x',
      hide: true,
      animated: false,
      legend: {
        id: 'truck_legend_v2x'
      },
      path: {
        id: 'pathline_v2x_rsu_v2x'
      }
    },

};

  linesOnDiagramWidths = [];
  rectsHorizontalWidths = [];
  rectsVerticalHeights = [];

  alwaysShow = [];

  html0 = '';

  ngOnInit() {
    for (const key in this.page) {
      if (!this.page[key].hide) {
        this.alwaysShow.push(key);
      }
    }
    this.byId(this.page.car_equipmet_adas.id).addEventListener('click', this.clickControllerAdas.bind(this));
    this.byId(this.page.car_equipmet_controller_egnine.id).addEventListener('click', this.clickControllerEngine.bind(this));
    this.byId(this.page.car_equipmet_technical_vision_controller.id).addEventListener('click', this.clickControllerVision.bind(this));
    this.byId(this.page.standartBO.id).addEventListener('click', this.clickStandartBO.bind(this));
    this.byId(this.page.notstandartBO_gnss_acselerometr.id).addEventListener('click', this.clickNotStandartBO.bind(this));
    this.makeAnimations();

    this.html0 += this.animateItem(this.glowingAnimation({'xlink:href': '#notstandartBO_gnss_acselerometr', values: '0.4; 1; 0.4', dur: '1s', begin: 'indefinite', repeatCount: 'indefinite'}));
    this.html0 += this.animateItem(this.glowingAnimation({'xlink:href': '#standartBO', values: '0.4; 1; 0.4', dur: '1s', begin: 'indefinite', repeatCount: 'indefinite'}));
    document.querySelector('#animationAlways').innerHTML = this.html0;
    const element00: SVGElement = document.querySelectorAll('svg')[2].getElementsByTagName('animate')[0] as SVGElement;
    const element01: SVGElement = document.querySelectorAll('svg')[2].getElementsByTagName('animate')[1] as SVGElement;
    element00.beginElement();
    element01.beginElement();

  }

  byId(id) {
    return document.getElementById(id);
  }

  makeAnimations() {
    let html = '';

    // tslint:disable-next-line:forin
    for (const key in this.page) {

      if (this.page[key].id) {
        html += this.animateItem(this.opacityAnimation({ id: this.page[key].id }));
      }

      if (this.page[key].legend) {
        html += this.animateItem(this.opacityAnimation({ id: this.page[key].legend.id }));
      }

      if (this.page[key].path) {
        if (this.page[key].path.animation) {
          const animation = {id: this.page[key].path.id, ...this.page[key].path.animation };
          html += this.animateItem(this.pathAnimation(animation));
        } else {
          html += this.animateItem(this.pathAnimation({id: this.page[key].path.id}));
        }
        html += this.animateItem(this.opacityAnimation({ id: this.page[key].path.id }));
      }
    }

    this.animateAutoDataPlatform('pathline4G_telematic_platform_Show.end').forEach(item => {
      html += this.animateItem(item);
    });

    html += this.animateItem(this.opacityAnimation({id: 'pathline_v2xplatform_autodata_4', dur: '1s', begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.pathAnimation({id: 'pathline_v2xplatform_autodata_4', repeatCount: 'indefinite', from: 0, to: 24, begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.opacityAnimation({id: 'pathline_v2xplatform_autodata_3', dur: '1s', begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.pathAnimation({id: 'pathline_v2xplatform_autodata_3', repeatCount: 'indefinite', from: 0, to: 24, begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.opacityAnimation({id: 'pathline_v2xplatform_autodata_2', dur: '1s', begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.pathAnimation({id: 'pathline_v2xplatform_autodata_2', repeatCount: 'indefinite', from: 0, to: 24, begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.opacityAnimation({id: 'pathline_v2xplatform_autodata', dur: '1s', begin: 'pathline4G_telematic_platform_Show.end'}));
    html += this.animateItem(this.pathAnimation({id: 'pathline_v2xplatform_autodata', repeatCount: 'indefinite', from: 0, to: 24, begin: 'pathline4G_telematic_platform_Show.end'}));


    this.animateV2XPlatform('pathline4G_telematic_platform_Show.end').forEach(item => { html += this.animateItem(item); });
    this.animateTelematicsPlatform('pathline4G_telematic_platform_Show.end').forEach(item => { html += this.animateItem(item); });
    this.animateMovingQueue('coll_1_', 'pathline4G_telematic_platform_Show.end + 1.5s',
        ['#Rectangle 7.4_2', '#Rectangle 7.5_2', '#Rectangle 7.6_2', '#Rectangle 7.7_2', '#Rectangle 7.8_2', '#Rectangle 7.9_2']
    ).forEach(item => { html += this.animateItem(item); });
    this.animateMovingQueue('coll_2_', 'pathline4G_telematic_platform_Show.end + 0.5s',
        ['#Rectangle 7.4_3', '#Rectangle 7.5_3', '#Rectangle 7.6_3', '#Rectangle 7.7_3', '#Rectangle 7.8_3', '#Rectangle 7.9_3']
    ).forEach(item => { html += this.animateItem(item); });
    this.animateMovingQueue('coll_3_', 'pathline4G_telematic_platform_Show.end',
        ['#Rectangle 7.4_4', '#Rectangle 7.5_4', '#Rectangle 7.6_4', '#Rectangle 7.7_4', '#Rectangle 7.8_4', '#Rectangle 7.9_4']
    ).forEach(item => { html += this.animateItem(item); });
    this.animateMovingQueue('coll_4_', 'pathline4G_telematic_platform_Show.end + 1s',
        ['#Rectangle 7.4', '#Rectangle 7.5', '#Rectangle 7.6', '#Rectangle 7.7', '#Rectangle 7.8', '#Rectangle 7.9']
    ).forEach(item => { html += this.animateItem(item); });

    this.animateDiagrams('coll_1_flowExchangeLeft0.end + 1s').forEach(item => { html += this.animateItem(item); });

    document.querySelector('#animation').innerHTML = html;

  }

  clickStandartBO(event) {
    if (this.page.standartBO.animated) {

      this.stopAnimation();

      return;
    }

    this.page.standartBO.hide = false;

    this.toggleVisibility(this.page);

    this.animate2(this.page.sensor_lane_retention_sensor, 0);
    this.animate2(this.page.sensor_ebs_esp, 0);
    this.animate2(this.page.sensor_controller_egnine, 0);
    this.animate2(this.page.sensor_speed, 0);
    this.animate2(this.page.sensor_lidar, 0);
    this.animate2(this.page.sensor_camera, 0);
    this.animate2(this.page.sensor_radar, 0);

    this.animate2(this.page.car_equipmet_adas, 2);
    this.animate2(this.page.car_equipmet_controller_egnine, 2);
    this.animate2(this.page.car_equipmet_technical_vision_controller, 2);
    this.animate2(this.page.car_equipmet_sau, 2);

    this.animate2(this.page.car_equipmet_era_glonass, 4);
    this.animate2(this.page.car_equipmet_can, 4);
    this.animate2(this.page.car_equipmet_obu_v2x, 4);

    this.animate2(this.page.rsu_v2x, 6);

    this.animate2(this.page.standartBO, 7);

    this.animate2(this.page.satellit4g, 8);

  }

  clickNotStandartBO(event) {
    if (this.page.notstandartBO_gnss_acselerometr.animated) {

      this.stopAnimation();

      return;
    }

    this.page.notstandartBO_gnss_acselerometr.hide = false;

    this.toggleVisibility(this.page);

    this.animate2(this.page.truck_equipmet_fuel_level_sensor, 0);
    this.animate2(this.page.truck_equipmet_doorsensor, 0);
    this.animate2(this.page.truck_equipmet_temperature_sensor, 0);

    this.animate2(this.page.truck_equipmet_platon, 3);
    this.animate2(this.page.truck_equipmet_tachograph, 3);
    this.animate2(this.page.truck_equipmete_glonass, 3);

    this.animate2(this.page.truck_equipmet_v2x, 5);

    this.animate2(this.page.rsu_v2x, 6);

    this.animate2(this.page.notstandartBO_gnss_acselerometr, 7);

    this.animate2(this.page.satellit4g, 8);

  }

  clickControllerAdas(event) {
    if (this.page.car_equipmet_adas.animated) {

      this.stopAnimation();

      return;
    }

    this.page.car_equipmet_adas.hide = false;
    this.page.car_equipmet_controller_egnine.hide = false;
    this.page.car_equipmet_technical_vision_controller.hide = false;

    this.toggleVisibility(this.page);

    this.animate2(this.page.sensor_lane_retention_sensor, 0);
    this.animate2(this.page.sensor_ebs_esp, 0);

    this.animate2(this.page.car_equipmet_adas, 2);

    this.animate2(this.page.car_equipmet_can, 4);

    this.animate2(this.page.standartBO, 7);

    this.animate2(this.page.satellit4g, 8);

  }

  clickControllerEngine(event) {
    if (this.page.car_equipmet_controller_egnine.animated) {

      this.stopAnimation();

      return;
    }
    this.page.car_equipmet_controller_egnine.hide = false;
    this.page.car_equipmet_adas.hide = false;
    this.page.car_equipmet_technical_vision_controller.hide = false;

    this.toggleVisibility(this.page);

    this.animate2(this.page.sensor_controller_egnine, 0);
    this.animate2(this.page.sensor_speed, 0);

    this.animate2(this.page.car_equipmet_controller_egnine, 2);

    this.animate2(this.page.car_equipmet_can, 4);

    this.animate2(this.page.standartBO, 7);

    this.animate2(this.page.satellit4g, 8);

  }

  clickControllerVision(event) {
    if (this.page.car_equipmet_technical_vision_controller.animated) {

      this.stopAnimation();

      return;
    }

    this.page.car_equipmet_controller_egnine.hide = false;
    this.page.car_equipmet_adas.hide = false;
    this.page.car_equipmet_technical_vision_controller.hide = false;

    this.toggleVisibility(this.page);

    this.animate2(this.page.sensor_lidar, 0);
    this.animate2(this.page.sensor_camera, 0);
    this.animate2(this.page.sensor_radar, 0);

    this.animate2(this.page.car_equipmet_technical_vision_controller, 2);
    this.animate2(this.page.car_equipmet_sau, 2);

    this.animate2(this.page.car_equipmet_can, 4);

    this.animate2(this.page.standartBO, 7);

    this.animate2(this.page.satellit4g, 8);
  }

  animate2(object, delay = 0) {
    if (object.animated) {
      return;
    }

    object.animated = true;

    if (object.hide) {
      this.animate(object.id + '_Show', delay);
    } else {
      delay--;
    }


    if (object.legend && object.hide) {
      this.animate(object.legend.id + '_Show', delay + 1);
    }  else {
      delay--;
    }

    if (object.path) {
      this.animate(object.path.id + '_Path', delay + 2);
      this.animate(object.path.id + '_Show', delay + 2);
    }
  }

  animate(id, delay = 0) {
    const element: SVGElement = document.querySelectorAll('[id=animation]')[0].querySelectorAll('[id=' + id + ']')[0] as SVGElement;
    setTimeout(() => {
      element.beginElement();
    }, delay * 1000);
  }

  stopAnimation() {
    this.toggleVisibility(this.page, 1);
    // tslint:disable-next-line:forin
    for (const key in this.page) { this.page[key].animated = false; this.page[key].hide = this.alwaysShow.indexOf(key) === -1; }
    const elements = Array.from(document.querySelectorAll('[id=animation]')[0].querySelectorAll('animate'));
    elements.forEach((element: SVGElement) => { element.endElement(); });
    document.querySelectorAll('[id=animation]')[0].innerHTML = '';
    setTimeout(() => { this.makeAnimations(); }, 100);
  }

  // turn off the visibility of selector by changing the opacity to 0
  toggleVisibility(object, visibility = 0) {
    // tslint:disable-next-line:forin
    for (const key in object) {
      if (visibility === 0) {
        if (object[key].hide && !object[key].animated) {
          this.byId(object[key].id).style.opacity = visibility.toString();
          if (object[key].legend) {
            this.byId(object[key].legend.id).style.opacity = visibility.toString();
          }
        }
        if (object[key].path) {
          this.byId(object[key].path.id).style.opacity = visibility.toString();
        }
      } else {
        this.byId(object[key].id).style.opacity = '1';
        if (object[key].legend) {
          this.byId(object[key].legend.id).style.opacity = '1';
        }
        if (object[key].path) {
          this.byId(object[key].path.id).style.opacity = '1';
        }
      }

    }
  }

  // animation for opacity
  opacityAnimation(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = (item.id.indexOf('#') > -1) ? item.id : '#' + item.id;
    animateObject.id = item.id + '_Show' || '';
    animateObject.attributeName = 'opacity';
    animateObject.from = (item.from < 0 || item.from === undefined) ? this.defaultOpacityFrom : item.from ;
    animateObject.to = (item.to < 0 || item.to === undefined) ? this.defaultOpacityTo : item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.begin = item.begin || 'indefinite';
    animateObject.repeatCount = item.repeatCount || '1';

    return animateObject;
  }

  // animation for glowing
  glowingAnimation(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'] || item.id + '_Glow' || '';
    animateObject.id = item.id + '_Glow' || '';
    animateObject.attributeName = 'opacity';
    animateObject.values = item.values || `1;${this.defaultGlowingMinOpacity};1`;
    animateObject.dur = item.dur || this.defaultGlowingDuration;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    if (item.begin) {
      animateObject.begin = item.begin;
    }
    if (item.repeatCount) {
      animateObject.repeatCount = item.repeatCount;
    }
    return animateObject;
  }

  // animation for drowing path
  pathAnimation(item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'] || '#' + item.id;
    animateObject.id = item.id + '_Path' || '';
    animateObject.attributeName = 'stroke-dashoffset';
    animateObject.from = item.from || this.defaultStrokeDashOffsetStart;
    animateObject.to = item.to || this.defaultStrokeDashOffsetEnd;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.begin = item.begin || 'indefinite';
    animateObject.repeatCount = item.repeatCount || 'indefinite';
    return animateObject;
  }

  // animation for moving blocks on x/y axis
  animateMoving(axis, item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = axis;
    animateObject.from = item.from;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    animateObject.begin = item.begin || 'indefinite';
    return animateObject;
  }

  // animation for drawing lines at charts or width/height of element
  animateWH(WH, item) {
    const animateObject: any = {};
    animateObject['xlink:href'] = item['xlink:href'];
    animateObject.attributeName = WH;
    animateObject.from = item.from || 0;
    animateObject.to = item.to;
    animateObject.dur = item.dur || this.defaultAnimationTime;
    animateObject.fill  = item.fill || this.defaultAnimationFill;
    animateObject.id = item.id || '';
    animateObject.begin = item.begin || 'indefinite';
    animateObject.repeatCount = item.repeatCount || '1';
    return animateObject;
  }

  // block of animations for animating diagram
  animateDiagrams(startAnimation) {
    const arrayOfAnimation = [];
    const durationForCharts = '3s';
    // Pie chart
    // Ellipses
    const ellipsesOnDiagram = ['Ellipse_19', 'Ellipse_20', 'Ellipse_21', 'Ellipse 2', 'Ellipse 2.1', 'Ellipse 2.2'];
    // Lines
    const linesOnDiagram = ['Rectangle 9', 'Rectangle 9.1', 'Rectangle 9.2', 'Rectangle 9.3', 'Rectangle 9.4', 'Rectangle 9.5'];
    // Map
    const ellipsesOnMap = ['Ellipse_9', 'Ellipse_10', 'Ellipse_11', 'Ellipse_12', 'Ellipse_13', 'Ellipse_14', 'Ellipse_15', 'Ellipse_16', 'Ellipse_17', 'Ellipse_18'];
    // Chart Lines
    const lines = ['Vector 44', 'Vector 45', 'Vector 46'];
    // Chart diagram (horizontal)
    const rectsHorizontal = ['Rectangle 10_2', 'Rectangle 10.1', 'Rectangle 10.2', 'Rectangle 10.3', 'Rectangle 10.4', 'Rectangle 10.5', 'Rectangle 10.6', 'Rectangle 10.7'];
    // Chart diagram (vertical)
    const rectsVertical = ['Rectangle 11', 'Rectangle 11.1', 'Rectangle 11.3', 'Rectangle 11.4', 'Rectangle 11.5', 'Rectangle 11.6', 'Rectangle 11.2', 'Rectangle 11.7', 'Rectangle 11.8', 'Rectangle 11.9', 'Rectangle 11.10'];

    // Start Showing ellipses on pie diagram
    ellipsesOnDiagram.forEach((item, i) => {
      arrayOfAnimation.push(this.opacityAnimation( {id: item, dur: durationForCharts, begin: `${startAnimation}`}));
    });

    // Start showing lines on pie diagram
    linesOnDiagram.forEach((item, i) => {
      const width = this.byId(item).getAttribute('width');
      if (!this.linesOnDiagramWidths[i]) {
        this.linesOnDiagramWidths.push(width);
      }
      this.byId(item).setAttribute('width', '0');
      arrayOfAnimation.push(this.animateWH('width', {'xlink:href': `#${item}`, to: this.linesOnDiagramWidths[i], begin: startAnimation}));
    });

    // Start showing ellipses on map
    ellipsesOnMap.forEach((item, i) => {
      arrayOfAnimation.push(
        this.opacityAnimation({id: item, dur: '1s', begin:  `${(i > 0) ? ellipsesOnMap[i - 1] + '_Show.end' : startAnimation }`})
      );
    });

    // Start showing lines on linearChart
    lines.forEach((item, i) => {
      this.byId(item).style['stroke-dashoffset'] = 550;
      this.byId(item).style['stroke-dasharray'] = 550;
      arrayOfAnimation.push(this.pathAnimation({'xlink:href': `#${item}`, dur: '5s', begin: startAnimation, from: '550'}));
    });

    // Start showing lines on barChart - horizontal
    rectsHorizontal.forEach((item, i) => {
      const width = this.byId(item).getAttribute('width');
      if (!this.rectsHorizontalWidths[i]) {
        this.rectsHorizontalWidths.push(width);
      }
      this.byId(item).setAttribute('width', '0');
      arrayOfAnimation.push(this.animateWH('width', {'xlink:href': `#${item}`, to: this.rectsHorizontalWidths[i], begin: startAnimation, dur: '3s'}));
    });

    // Start showing lines on barChart - vertical
    rectsVertical.forEach((item, i) => {
      const height = +(this.byId(item).getAttribute('height'));
      if (!this.rectsVerticalHeights[i]) {
        this.rectsVerticalHeights.push(height);
      }
      this.byId(item).setAttribute('height', '0');
      const startCoordinate = Math.abs(169 - this.rectsVerticalHeights[i]);
      arrayOfAnimation.push(this.animateWH('height', {'xlink:href': `#${item}`, to: this.rectsVerticalHeights[i], dur: '3s', begin: startAnimation}));
      arrayOfAnimation.push(this.animateMoving('y', {'xlink:href': `#${item}`, to: startCoordinate, from: '169', dur: '3s', begin: startAnimation}));
    });

    return arrayOfAnimation;
  }

  // block of animation for moving elements
  animateMovingQueue(suffix, startAnimation, elements) {
    const component = this;
    // Elements in the queue, six because one of them is flying
    // const elements = ['#Rectangle 7.4_2', '#Rectangle 7.5_2', '#Rectangle 7.6_2', '#Rectangle 7.7_2', '#Rectangle 7.8_2', '#Rectangle 7.9_2'];
    const durationForOpacity = '0.01s';
    const durationForFlying = '0.01s';

    function cycling(object1) {
      const arrayOfAnimation = [];
      for (let i = 0; i < 6; i++) {
        if (i === 0) {
          arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[i]}`, begin: `${suffix}flowExchangeCompleted5.end; ${suffix}endCycleAnimation${i + 5}.end`, from: '285', to: '255', id: `${suffix}flowExchangeLeft${i}`, dur: durationForFlying}));
        } else {
          arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[i]}`, begin: `${suffix}endCycleAnimation${i - 1}.end`, from: '285', to: '255', id: `${suffix}flowExchangeLeft${i}`, dur: durationForFlying}));
        }
        arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[((i + 1) > 5) ? (i + 1 - 6) : (i + 1)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '305', to: '285'}));
        arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[((i + 2) > 5) ? (i + 2 - 6) : (i + 2)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '325', to: '305'}));
        arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[((i + 3) > 5) ? (i + 3 - 6) : (i + 3)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '345', to: '325'}));
        arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[((i + 4) > 5) ? (i + 4 - 6) : (i + 4)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '365', to: '345'}));
        arrayOfAnimation.push(object1.opacityAnimation({id: `${elements[((i + 5) > 5) ? (i + 5 - 6) : (i + 5)]}`, dur: durationForOpacity, begin: `${suffix}flowExchangeLeft${i}.end`}));
        arrayOfAnimation.push(object1.animateMoving('y', {'xlink:href': `${elements[((i + 5) > 5) ? (i + 5 - 6) : (i + 5)]}`, begin: `${suffix}flowExchangeLeft${i}.end`, from: '385', to: '365', id: `${suffix}endCycleAnimation${i}`}));
        arrayOfAnimation.push(object1.opacityAnimation({id: `${elements[i]}`, dur: durationForOpacity, begin: `${suffix}flowExchangeLeft${i}.end`, from: '1', to: '0'}));
      }
      // TODO: trail of the element (?) - no need to put it in cycle
      return arrayOfAnimation;
    }

    const sequence1 = [
      // preparing queue of information
      this.opacityAnimation({id: elements[0], dur: durationForOpacity, begin: startAnimation}),
      this.animateMoving('y', {'xlink:href': elements[0], begin: startAnimation, from: '405', to: '285', id: `${suffix}flowExchangeCompleted1`}),
      this.opacityAnimation({id: elements[1], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted1.end`}),
      this.animateMoving('y', {'xlink:href': elements[1], begin: `${suffix}flowExchangeCompleted1.end`, from: '405', to: '305', id: `${suffix}flowExchangeCompleted2`}),
      this.opacityAnimation({id: elements[2], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted2.end`}),
      this.animateMoving('y', {'xlink:href': elements[2], begin: `${suffix}flowExchangeCompleted2.end`, from: '405', to: '325', id: `${suffix}flowExchangeCompleted3`}),
      this.opacityAnimation({id: elements[3], dur: durationForOpacity, begin: `${suffix}flowExchangeCompleted3.end`}),
      this.animateMoving('y', {'xlink:href': elements[3], begin: `${suffix}flowExchangeCompleted3.end`, from: '405', to: '345', id: `${suffix}flowExchangeCompleted4`}),
      this.opacityAnimation({id: elements[4], dur: durationForOpacity, begin: 'flowExchangeCompleted4.end'}),
      this.animateMoving('y', {'xlink:href': elements[4], begin: `${suffix}flowExchangeCompleted4.end`, from: '405', to: '365', id: `${suffix}flowExchangeCompleted5`}),
    ];

    return [ ...sequence1, ...cycling(component)];
  }

  // animate glowing AutoDataPlatform
  animateAutoDataPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 1; i <= 9; i++) {
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#hex_odd_${i}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `autoDataPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // animate glowing V2XPlatform
  animateV2XPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 1; i <= 3; i++) {
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#hex_odd_2.${i}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `V2XPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // animate glowing Telematics
  animateTelematicsPlatform(startAnimation) {
    const animationArray: any = [];
    for (let i = 0; i < 60; i++) {
      if (i && (i % 2 === 0)) {
        continue;
      }
      animationArray.push(
        this.glowingAnimation({
          'xlink:href': `#Rectangle 8.2${i ? '_' + i : ''}`,
          repeatCount: 'indefinite',
          begin: startAnimation,
          id: `TelematicsPlatformGlow${i}`
        })
      );
    }
    return animationArray;
  }

  // make animation tag
  animateItem(item) {
    let html = '<animate ';
    Object.keys(item).forEach((key) => {
      html += `${key}="${item[key]}" `;
    });
    html += ' />';
    return html;
  }

}
