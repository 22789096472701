import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from '@/app.component';
import {SecondComponent} from '@/second/second.component';
import {FirstComponent} from "@/first/first.component";

const routes: Routes = [
  {
    path: '',
    component: FirstComponent
  },
  {
    path: 'public',
    component: FirstComponent
  },
  {
    path: 'special',
    component: SecondComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
